<template>
	<PopupTwoCol name="astrolabe"
	text="<h3>Astrolabe</h3><br/><p>C'est dans ce bâtiment administratif que travaillent les fonctions supports de la raffinerie. On y retrouve des services tels que les Ressources Humaines, la comptabilité, l'informatique, la communication et bien d'autres...</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/astrolabe_q0q7vh_c_scale_w_350.jpg 350w,
		img/popup/astrolabe_q0q7vh_c_scale_w_710.jpg 710w,
		img/popup/astrolabe_q0q7vh_c_scale_w_970.jpg 970w"
		data-src="img/popup/astrolabe_q0q7vh_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
